export enum PAGES {
  ROOT = "/",
  ROOT_EN = "/en/",
  ONLINE_ORDER_EN = "/en/order-food-online",
  BACK_BOXES='/zpatkykekrabickam',
  SWITCH_BOXES = '/pridej-se-k-nam/',
  NOT_FOUND = "*",
  DIET_BRNO = "/krabickova-dieta-brno",
  DIET_HRADEC = "/krabickova-hradec",
  DIET_JABLONEC = "/krabickova-dieta-jablonec",
  DIET_KLADNO = "/krabickova-dieta-kladno",
  DIET_LIBEREC = "/krabickova-dieta-liberec",
  DIET_MLDA = "/krabickova-dieta-mlda-boleslav",
  DIET_PARADUBICE = "/krabickova-dieta-pardubice",
  DIET_PLZEN = "/krabickova-dieta-plzen",
  DIET_PRAHA = "/krabickova-dieta-praha-a-okoli/",
  DIET_SREDNI_CECHI = '/krabickova-dieta-stredni-cechy',
  DIET_HRADEC_KRALOVE = '/krabickova-dieta-hradec-kralove',
  DIET_JABLONEC_NISOU = '/krabickova-dieta-jablonec-nad-nisou',
  DIET_MLDA_BOLESLAV = '/krabickova-dieta-mlada-boleslav',
  GDPR = "/gdpr",
  ONLINE_ORDER = "/order-food-online",
  TERMS = "/terms",
  ACE_TERMS = '/pravidla-akci',
  THANK_YOU = "/thank-you",
  THANK_YOU_AFFILIATE = "/thank-you-affiliate",
  VYZKOUSEJ_MENU = "/vyzkousej-menu",
  PAYMENT_NOT_COMPLATE = '/payment-not-completed',
  THANK_YOU_ORDER = '/thank-you-order'

}

type ParsedSearchParams = Record<string, string>;

export type Scheme<Endpoints extends PAGES = PAGES> = {
  scheme: Endpoints;
  params?: Record<string, string>;
  getParams?: ParsedSearchParams;
};

export type FeScheme = Scheme<PAGES> | [Scheme<PAGES>];
